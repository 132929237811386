import { CheckinStatus } from 'components/checkin-status-label'

export const levelStatus = (
  level?: number | null,
  requiredLevel?: number | null
): CheckinStatus => {
  if ((!level && level !== 0) || (!requiredLevel && requiredLevel !== 0))
    return 'not_checked_in'

  if (level > requiredLevel) {
    return 'exceeding'
  } else if (level < requiredLevel) {
    return 'working_towards'
  } else {
    return 'meeting'
  }
}
